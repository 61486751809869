import { axiosApi } from "../helpers/api_helper";

export class StoreRepository {
  endpoint = "/store";

  async uploadLogo(data) {
    const response = await axiosApi.put(`${this.endpoint}/update-logo`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async getStore() {
    const response = await axiosApi.get(`${this.endpoint}/about`);
    return response.data;
  }

  async partialUpdate(data) {
    const response = await axiosApi.put(this.endpoint, data);
    if (response.status === 200) {
      return response.data;
    }
    //TODO: raise domain exceptions to handle error when request fail
  }

  async getSchedule() {
    const response = await axiosApi.get(`${this.endpoint}/schedule`);
    return response.data;
  }

  async saveSchedule(data) {
    const response = await axiosApi.post(`${this.endpoint}/schedule`, data);
    return response.data;
  }

  async getUserByStore() {
    const response = await axiosApi.get("/users_by_store");
    return response.data;
  }
}

export default StoreRepository;
