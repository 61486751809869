import axios from "axios";
import i18next from "i18next";
import { toast } from "react-toastify";
import { LoginRepository } from "../repositories/login";

const API_URL = process.env.REACT_APP_BASE_URL;

export const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((config) => {
  const loginRepository = new LoginRepository();
  const token = loginRepository.getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosApi.interceptors.response.use(undefined, async (error) => {
  const statusCode = error.response.status,
    url = error.response.config.url;
  if (statusCode === 401 && !["/auth/login", "/auth/register"].includes(url)) {
    const repository = new LoginRepository();
    repository.logOut();
    window.location.href = "/login";
  } else if (statusCode >= 500) {
    toast.error(i18next.t("Something went wrong"), {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return error;
});
