import { createSlice } from "@reduxjs/toolkit";
import { FILTER_ALL } from "../../../../../core/constants/prize_redeemed_filter";

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  filter: FILTER_ALL,
};

export const prizeRedeemedSlice = createSlice({
  name: "prize/redeemed",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    loaded: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});
export const prizeRedeemedActions = prizeRedeemedSlice.actions;
export default prizeRedeemedSlice.reducer;
