import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  count: 0
};

export const prizeMemberStatistics = createSlice({
  name: "prize/membersStatistics",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.data = null;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setCurrentMonth: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },
    setCount:(state, action) => {
      console.log("$$$$$", action);
        state.isLoading = false;
        state.error = null;
        state.count = action.payload;
      },
  },
});
export const membersStaticsActions = prizeMemberStatistics.actions;

export default prizeMemberStatistics.reducer;
