import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  saving: false,
  data: {},
};

export const storeScheduleSlice = createSlice({
  name: "store/schedule",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.data = [];
    },
    loaded: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    save: (state, action) => {
      state.saving = true;
    },
    saved: (state, action) => {
      state.saving = false;
    },
    update: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});
export const storeScheduleActions = storeScheduleSlice.actions;
export default storeScheduleSlice.reducer;
