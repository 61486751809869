import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Modal,
  Card,
  ModalHeader,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Select from "react-select";
import { CountriesRepository } from "../../repositories/countries";
import { StoreCategoriesRepository } from "../../repositories/store_categories";
import { LoginRepository } from "../../repositories/login";

import beeLogo from "../../assets/images/logo-light.svg";
import sideImg from "../../assets/images/layouts/register.svg";
import "../../assets/scss/custom/pages/_register.scss";
import { TermAndCondition } from "./TermAndCondition";

const Register = (props) => {
  document.title = "Registro";
  const { history } = props;
  const [countriesList, setCountriesList] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [modal, setModal] = useState(false);
  const [termsCondition, setTermsCondition] = useState(false);
  const [error, setError] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      businessName: "",
      businessType: "",
      country: "",
      email: "",
      telephone: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      businessName: Yup.string().required(
        "Por favor ingrese el nombre de su negocio"
      ),
      businessType: Yup.string().required(
        "Por favor seleccione el tipo de negocio"
      ),
      country: Yup.string().required("Por favor seleccione el país"),
      email: Yup.string()
        .required("Por favor ingrese su correo electronico")
        .email("Ingrese un correo valido"),
      password: Yup.string()
        .required("Por favor ingrese su contraseña")
        .matches(
          /^(?=.*[a-z|A-Z|0-9])(?=.{3,})/,
          "Debe contener al menos 3 caracteres"
        ),
      confirmPassword: Yup.string()
        .required("Por favor confirme su contraseña")
        .oneOf([Yup.ref("password")], "Su contraseña no coincide"),
      telephone: Yup.number().required(
        "Por favor ingrese su numero de telefono"
      ),
    }),

    onSubmit: async (values) => {
      const payload = {
        name: values.businessName,
        store_category_id: parseInt(values.businessType),
        country_id: parseInt(values.country),
        contact_email: values.email,
        contact_phone: values.telephone,
        password: values.confirmPassword,
        username: values.email,
      };
      setError(null);
      try {
        await new LoginRepository().register(payload);
        history.push("/payment");
      } catch (e) {
        setError(e.toString());
      }
    },
  });

  useEffect(() => {
    new CountriesRepository()
      .listAll()
      .then((data) =>
        setCountriesList(
          data.map((item) => ({ value: item.id, label: item.name }))
        )
      )
      .catch((error) => console.log(error));

    new StoreCategoriesRepository()
      .listAll()
      .then((data) =>
        setStoreData(data.map((item) => ({ value: item.id, label: item.name })))
      )
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <div className="register_form">
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <div className="account-pages my-0 pt-sm-0 main_form">
          <Container className="p-0 m-0 main_div">
            <Row className="justify-content-center">
              <Col className="p-0" md={0} lg={0} xl={9}>
                <div className="form_side_img p-0 text-center">
                  <img src={sideImg} alt="" className="img-fluid" />
                </div>
              </Col>
              <Col className="p-0" md={0} lg={0} xl={3}>
                <Card className="overflow-hidden p-0">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7"></Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="brand_logo p-2 pb-3">
                      <Col className="col-12">
                        <img
                          src={beeLogo}
                          alt=""
                          className="pb-2"
                          width="130"
                        />
                      </Col>
                      <div className="form_upper_text mt-2">
                        <h1>Registro</h1>
                        <p className="m-0 muted">
                          Crea tu cuenta fácil y rápido
                        </p>
                      </div>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3 main_form">
                          <Label className="form-label">
                            Nombre del Negocio
                          </Label>
                          <Input
                            id="business-name"
                            name="businessName"
                            className="form-control fw-input"
                            placeholder="Nombre de la empresa"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.businessName || ""}
                            invalid={
                              validation.touched.businessName &&
                              validation.errors.businessName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.businessName &&
                          validation.errors.businessName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.businessName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="control-label">
                            Tipo de Negocio
                          </Label>
                          <Select
                            id="business-type"
                            name="businessType"
                            placeholder="Categoría de Comercio"
                            classNamePrefix="select2-selection"
                            className="fw-input"
                            options={storeData}
                            title="businessType"
                            onChange={(e) => {
                              validation.setFieldValue("businessType", e.value);
                            }}
                            onBlur={() =>
                              validation.setFieldTouched("businessType", true)
                            }
                            invalid={
                              validation.touched.businessType &&
                              validation.errors.businessType
                                ? true
                                : false
                            }
                          />
                          {validation.touched.businessType &&
                          validation.errors.businessType ? (
                            <FormFeedback className="d-block" type="invalid">
                              {validation.errors.businessType}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="control-label">País</Label>
                          <Select
                            id="country"
                            name="country"
                            classNamePrefix="select2-selection"
                            placeholder="Selecciona tu país"
                            options={countriesList}
                            className="fw-input"
                            onChange={(e) => {
                              validation.setFieldValue("country", e.value);
                            }}
                            invalid={
                              validation.touched.country &&
                              validation.errors.country
                                ? true
                                : false
                            }
                          />
                          {validation.touched.country &&
                          validation.errors.country ? (
                            <FormFeedback className="d-block" type="invalid">
                              {validation.errors.country}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control fw-input"
                            placeholder="Correo Electrónico"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Teléfono</Label>
                          <Input
                            id="telephone"
                            name="telephone"
                            className="form-control fw-input"
                            placeholder="Número"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.telephone || ""}
                            invalid={
                              validation.touched.telephone &&
                              validation.errors.telephone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.telephone &&
                          validation.errors.telephone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.telephone}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Contraseña</Label>
                          <Input
                            name="password"
                            className="fw-input"
                            type="password"
                            placeholder="Contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">
                            Confirmar Contraseña
                          </Label>
                          <Input
                            name="confirmPassword"
                            type="password"
                            className="fw-input"
                            placeholder="Confirmar Contraseñas"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-4">
                          <div className="my-4 ">
                            <input
                              id="acceptCondition"
                              type="checkbox"
                              checked={termsCondition}
                              onChange={() =>
                                setTermsCondition(!termsCondition)
                              }
                            />
                            <label className="mb-0">
                              Acepta los{" "}
                              <Link
                                to="#"
                                onClick={() => {
                                  setModal(!modal);
                                }}
                                className="text-primary link_text"
                              >
                                términos y condiciones
                              </Link>{" "}
                              de Bee
                            </label>
                          </div>
                          <div>
                            {error ? (
                              <span className="text-danger py-3">{error}</span>
                            ) : null}
                          </div>
                          <button
                            className="btn btn-block  text-white"
                            type="submit"
                            disabled={!termsCondition}
                          >
                            CONTINUAR
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Tnc Modal */}
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          size="xl"
          centered
          data-toggle="modal"
          toggle={() => {
            setModal(!modal);
          }}
        >
          <div>
            <ModalHeader
              className="border-bottom-0"
              toggle={() => {
                setModal(!modal);
              }}
            >
              Terminos y Condiciones
            </ModalHeader>
          </div>
          <ModalBody>
            <TermAndCondition />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Register;
