import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Button,
} from "reactstrap";
import logo from "../../assets/images/logo-bee-circle.svg";
import wp from "../../assets/images/grupo-995.svg";

//redux
import { useDispatch, useSelector } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  sendCode,
  validateCode,
  changePassword,
} from "../../app/store/slices/auth/recovery/effects";
import { authRecoveryActions } from "../../app/store/slices/auth/recovery/slice";

// action
//import { userForgetPassword } from "../../store/actions";

// import images

const ChangePasswordForm = ({ onChange, onBack, loading }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      passwordVerification: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Por favor ingrese su contraseña")
        .matches(
          /^(?=.*[a-z|A-Z|0-9])(?=.{3,})/,
          "Debe contener al menos 3 caracteres"
        ),
      passwordVerification: Yup.string()
        .required("Por favor confirme su contraseña")
        .oneOf([Yup.ref("password")], "Su contraseña no coincide"),
    }),
    onSubmit: (values) => {
      onChange(values.password);
    },
  });

  return (
    <Card className="overflow-hidden">
      <div className="bg-well bg-softbg-soft-primary">
        <Row>
          <Col xs={7}>
            <div className=" p-4">
              <h4 className="">Recuperar contraseña</h4>
              <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
            </div>
          </Col>
          <Col className="col-5 align-self-end">
            <img src={wp} alt="" className="img-fluid" height="90" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <div>
          <Link to="/">
            <div className="avatar-md profile-user-wid mb-4">
              <span className="avatar-title rounded-circle bg-light">
                <img src={logo} alt="" className="rounded-circle" height="90" />
              </span>
            </div>
          </Link>
        </div>
        <div className="p-2">
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <Label className="form-label">Contraseña</Label>
              <Input
                name="password"
                className="form-control"
                placeholder="Ingrese la nueva contraseña"
                type="password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.password || ""}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Confirmar contraseña</Label>
              <Input
                name="passwordVerification"
                className="form-control"
                placeholder="Confirmar contraseña"
                type="password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.passwordVerification || ""}
                invalid={
                  validation.touched.passwordVerification &&
                  validation.errors.passwordVerification
                    ? true
                    : false
                }
              />
              {validation.touched.passwordVerification &&
              validation.errors.passwordVerification ? (
                <FormFeedback type="invalid">
                  {validation.errors.passwordVerification}
                </FormFeedback>
              ) : null}
            </div>
            <Row className="mb-3">
              <Col sm="12">
                <Button
                  color="primary"
                  type="submit"
                  className="w-100"
                  disabled={loading}
                >
                  <span className="text-white">Cambiar Contraseña</span>
                </Button>
              </Col>
              <Col className=" mt-2" sm="12">
                <Button
                  color=""
                  type="button"
                  className="w-100"
                  onClick={onBack}
                >
                  Regresar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

const ValidateCodeForm = ({ onVerify, loading, error, onBack }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required("Por favir ingresa el codigo")
        .min(4, "El codigo debe tener almenos 4 caracteres"),
    }),
    onSubmit: (values) => {
      onVerify(values.code);
    },
  });

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    // forgetError: state.ForgetPassword.forgetError,
    // forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));
  return (
    <Card className="overflow-hidden">
      <div className="bg-well bg-softbg-soft-primary">
        <Row>
          <Col xs={7}>
            <div className=" p-4">
              <h4 className="">Recuperar contraseña</h4>
              <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
            </div>
          </Col>
          <Col className="col-5 align-self-end">
            <img src={wp} alt="" className="img-fluid" height="90" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <div>
          <Link to="/">
            <div className="avatar-md profile-user-wid mb-4">
              <span className="avatar-title rounded-circle bg-light">
                <img src={logo} alt="" className="rounded-circle" height="90" />
              </span>
            </div>
          </Link>
        </div>
        <div className="p-2">
          {forgetError && forgetError ? (
            <Alert color="danger" style={{ marginTop: "13px" }}>
              {forgetError}
            </Alert>
          ) : null}
          {forgetSuccessMsg ? (
            <Alert color="success" style={{ marginTop: "13px" }}>
              {forgetSuccessMsg}
            </Alert>
          ) : null}

          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <div>
                <p>Ingresa el código que te fue enviado a tu correo</p>
              </div>
              <Label className="form-label">Código</Label>
              <Input
                name="code"
                className="form-control"
                placeholder="Ingrese el código"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || ""}
                invalid={
                  validation.touched.code && validation.errors.code
                    ? true
                    : false
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>
            <Row className="mb-3">
              {error ? (
                <Col sm="12">
                  <Alert color="danger" style={{ marginTop: "13px" }}>
                    {error}
                  </Alert>
                </Col>
              ) : null}
              <Col sm="12">
                <Button
                  color="primary"
                  type="submit"
                  className="w-100"
                  disabled={loading}
                >
                  <span className="text-white">Ingresar</span>
                </Button>
              </Col>
              <Col className=" mt-2" sm="12">
                <Button
                  color=""
                  type="button"
                  className="w-100"
                  onClick={onBack}
                >
                  Regresar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

const EmailForm = ({ onSend, onBack, loading }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor ingresa tu correo"),
    }),
    onSubmit: (values) => {
      onSend(values.email);
    },
  });

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    // forgetError: state.ForgetPassword.forgetError,
    // forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));
  return (
    <Card className="overflow-hidden">
      <div className="bg-well bg-softbg-soft-primary">
        <Row>
          <Col xs={7}>
            <div className=" p-4">
              <h4 className="">Recuperar contraseña</h4>
              <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
            </div>
          </Col>
          <Col className="col-5 align-self-end">
            <img src={wp} alt="" className="img-fluid" height="90" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <div>
          <Link to="/">
            <div className="avatar-md profile-user-wid mb-4">
              <span className="avatar-title rounded-circle bg-light">
                <img src={logo} alt="" className="rounded-circle" height="90" />
              </span>
            </div>
          </Link>
        </div>
        <div className="p-2">
          {forgetError && forgetError ? (
            <Alert color="danger" style={{ marginTop: "13px" }}>
              {forgetError}
            </Alert>
          ) : null}
          {forgetSuccessMsg ? (
            <Alert color="success" style={{ marginTop: "13px" }}>
              {forgetSuccessMsg}
            </Alert>
          ) : null}

          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <div>
                <p>
                  Digite el correo electrónico con el que se registro en CR
                  Marine y le enviaremos un código de verificación
                </p>
              </div>
              <Label className="form-label">Email</Label>
              <Input
                name="email"
                className="form-control"
                placeholder="Ingrese su correo"
                type="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
            <Row className="mb-3">
              <Col sm="12">
                <Button
                  color="primary"
                  type="submit"
                  className="w-100"
                  disabled={loading}
                >
                  <span className="text-white">Enviar Código</span>
                </Button>
              </Col>
              <Col className=" mt-2" sm="12">
                <Button
                  color=""
                  type="button"
                  className="w-100"
                  onClick={onBack}
                >
                  Regresar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

const ForgetPasswordPage = (props) => {
  const recovery = useSelector((state) => state.auth.recovery);
  const dispatch = useDispatch();

  const history = useHistory();

  const { changed } = recovery;

  useEffect(() => {
    if (changed) {
      history.push("/login");
    }
  }, [history, changed]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              {!recovery.sent ? (
                <EmailForm
                  onSend={(email) => dispatch(sendCode(email))}
                  loading={recovery.sending}
                  onBack={() => history.push("/login")}
                />
              ) : !recovery.validated ? (
                <ValidateCodeForm
                  loading={recovery.sending}
                  error={recovery.error}
                  onVerify={(code) => dispatch(validateCode(code))}
                  onBack={() => {
                    dispatch(authRecoveryActions.reset());
                  }}
                />
              ) : (
                <ChangePasswordForm
                  loading={recovery.sending}
                  onChange={(password) =>
                    dispatch(
                      changePassword({
                        password,
                        code: recovery.code,
                        email: recovery.email,
                      })
                    )
                  }
                  onBack={() => dispatch(authRecoveryActions.reset())}
                />
              )}

              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} CR Marine</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
