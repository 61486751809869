import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import NonAuthLayout from "./components/NonAuthLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./assets/scss/theme.scss";

const App = () => {
  return (
    <React.Fragment>
      <ToastContainer theme="dark" />
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          <Suspense fallback={<h4>Cargando...</h4>}>
            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact={route.exact}
              />
            ))}
          </Suspense>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
