import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";
import { toast } from "react-toastify";
import StoreRepository from "../../../repositories/store";

const toastConfig = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialState = {
  isLoading: false,
  isSaving: false,
  error: null,
  store: null,
  logo: {
    isSaving: false,
    error: null,
  },
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.store = null;
    },
    setStore: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.store = action.payload;
    },
    update: (state, action) => {
      state.isSaving = true;
    },
    startUpdateLogo: (state, action) => {
      state.logo.isSaving = true;
    },
    updateLogo: (state, action) => {
      state.logo.error = null;
      state.logo.isSaving = false;
      state.store = { ...state.store, ...action.payload };
    },
    updateStore: (state, action) => {
      state.isSaving = false;
      state.store = { ...state.store, ...action.payload };
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.store = {};
    },
  },
});
export const storeActions = storeSlice.actions;
export default storeSlice.reducer;

export const loadStore = () => async (dispatch) => {
  dispatch(storeActions.load());
  const repository = new StoreRepository();
  try {
    const store = await repository.getStore();
    if (store) {
      dispatch(storeActions.setStore(store));
    } else {
      dispatch(storeActions.setStore({}));
    }
  } catch (err) {
    dispatch(storeActions.setError(err.toString()));
  }
};

export const partialUpdate = (data) => async (dispatch) => {
  dispatch(storeActions.update());
  const repository = new StoreRepository();
  const store = await repository.partialUpdate(data);
  if (store) {
    dispatch(storeActions.updateStore(data));
    toast.success(
      i18next.t("Store details have been successfully updated"),
      toastConfig
    );
  } else {
    toast.warn(
      i18next.t("It seems something has gone wrong, please try again"),
      toastConfig
    );
  }
};

export const uploadImage = (id, data) => async (dispatch) => {
  dispatch(storeActions.startUpdateLogo());
  const repository = new StoreRepository();
  const response = await repository.uploadLogo(data);
  if (response) {
    dispatch(storeActions.updateLogo({ url_logo: response["url_logo"] }));
    toast.success(i18next.t("The image has been updated"), toastConfig);
  } else {
    toast.warn(
      i18next.t("It seems something has gone wrong, please try again"),
      toastConfig
    );
  }
};
