import React from "react";
import { Redirect } from "react-router-dom";

import Logout from "../pages/Authentication/Logout";
import Login from "../pages/Authentication/Login";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Register from "../pages/Authentication/Register";

//Pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

const Dashboard = React.lazy(() => import("../pages/Dashboard"));

const authProtectedRoutes = [
  { path: "/logout", component: Logout, exact: true },
  // { path: "/payment", component: PaymentForm, exact: true },
  // { path: "/profile", component: UserProfile },
  //{ path: "/profile-details", component: ProfileDetails, exact: true },
  // { path: "/prize-info", component: PrizeSetUpInstruction, exact: true },
  //{ path: "/prize-setup/:pageNumber", component: PrizeSetUp, exact: true },
  { path: "/dashboard", component: Dashboard },
  // { path: "/wlcm-one", component: WelcomeOne, exact: true },
  // { path: "/wlcm-two", component: WelcomeTwo, exact: true },
  // { path: "/finish-up", component: FinishUpPage, exact: true },
  { path: "/pages-404", component: Pages404, exact: true },
  { path: "/pages-500", component: Pages500, exact: true },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { authProtectedRoutes, publicRoutes };
