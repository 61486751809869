export const TermAndCondition = () => {
  return (
    <div>
      <div>
        <p>
          <strong>I. Uso de la Plataforma</strong>
        </p>

        <p>
          Estos&nbsp;términos&nbsp;y&nbsp;condiciones&nbsp;de&nbsp;uso (en
          adelante, los “Términos”) regulan el acceso&nbsp;y&nbsp;uso la
          plataforma web cuya URL es{" "}
          <a href="http://www.puntosbee.com">www.puntosbee.com</a>{" "}
          y/o&nbsp;de&nbsp;las aplicaciones móvil de “Bee”, tanto para tablets
          como para celulares (en adelante, el “Sitio”).
        </p>

        <p>
          Al acceder, navegar&nbsp;y/o usar el Sitio, usted admite haber
          leído&nbsp;y&nbsp;entendido estos Términos y acuerda obligarse a los
          mismos, así como a cumplir con todas las leyes&nbsp;y&nbsp;reglamentos
          que sean aplicables. En consecuencia, al acceder, usar&nbsp;y/o
          adquirir cualquiera&nbsp;de&nbsp;los productos o servicios ofrecidos
          en el Sitio, usted libremente acepta&nbsp;y&nbsp;adhiere expresamente
          a estos&nbsp;Términos,&nbsp;así como todas sus
          modificaciones&nbsp;y&nbsp;adendas. En el evento en que usted no desee
          aceptar o se encuentre en desacuerdo con estos&nbsp;Términos, o sus
          modificaciones, deberá abandonar el Sitio inmediatamente.
          Bee&nbsp;pone el Sitio a disposición&nbsp;de&nbsp;los
          usuarios&nbsp;de&nbsp;la red Internet&nbsp;y&nbsp;en particular a
          disposición&nbsp;de&nbsp;sus clientes (en adelante los “Comercios” o
          el “Comercio”).
        </p>

        <p>
          <em>Modificaciones</em>
        </p>

        <p>
          Bee&nbsp;se reserva el derecho a modificar estos&nbsp;Términos&nbsp;en
          cualquier momento&nbsp;y&nbsp;sin previo aviso. Toda modificación
          entrará en vigor&nbsp;y&nbsp;tendrá efectos frente a los Comercios
          desde su publicación en el Sitio. En consecuencia, el Comercio deberá
          revisar estos&nbsp;Términos&nbsp;cada vez que acceda al Sitio.
          Asimismo, los avisos, reglamentos, circulares o
          instrucciones&nbsp;de&nbsp;cualquier naturaleza expedidas
          por&nbsp;Bee, publicadas en el
          Sitio,&nbsp;y&nbsp;relacionadas&nbsp;de&nbsp;cualquier forma con el
          acceso, navegación o uso del Sitio, o con el
          acceso&nbsp;y&nbsp;uso&nbsp;de&nbsp;los productos&nbsp;y/o servicios
          que en él se ofrecen, serán parte
          integral&nbsp;de&nbsp;estos&nbsp;Términos&nbsp;y&nbsp;prevalecerán
          sobre cualquier otra disposición relacionada aun cuando ésta le sea
          contraria.
        </p>

        <p>
          <em>Correcto uso del sitio</em>
        </p>

        <p>
          El Comercio se compromete a utilizar el Sitio&nbsp;de&nbsp;conformidad
          con las leyes de la República de Costa Rica&nbsp;y&nbsp;con lo
          dispuesto en estos&nbsp;Términos. El Comercio se
          abstendrá&nbsp;de&nbsp;utilizar el Sitio con fines o efectos ilícitos,
          lesivos&nbsp;de&nbsp;los derechos e intereses&nbsp;de&nbsp;terceros,
          o&nbsp;de&nbsp;realizar actos que de cualquier forma puedan dañar,
          inutilizar, sobrecargar, deteriorar o impedir el normal funcionamiento
          del Sitio.
        </p>

        <p>
          <em>Propiedad intelectual</em>
        </p>

        <p>
          Bee&nbsp;ha dispuesto en el Sitio ciertos contenidos tales como
          mensajes, diseños, códigos fuente, códigos objeto, animaciones,
          gráficos, archivos&nbsp;de&nbsp;sonido&nbsp;y/o imagen, fotografías,
          grabaciones,&nbsp;y&nbsp;software (los “Contenidos”).
        </p>

        <p>
          Los derechos&nbsp;de&nbsp;autor sobre los Contenidos
          son&nbsp;de&nbsp;propiedad&nbsp;de&nbsp;Bee o del creador
          original&nbsp;de&nbsp;los mismos&nbsp;de&nbsp;quien Bee ha recibido
          autorización para su uso,&nbsp;y&nbsp;están plenamente protegidos por
          las normas nacionales e
          internacionales&nbsp;de&nbsp;derechos&nbsp;de&nbsp;autor&nbsp;y&nbsp;propiedad
          intelectual. En consecuencia, salvo lo expresamente señalado en
          los&nbsp;Términos, el Comercio deberá abstenerse&nbsp;de&nbsp;copiar,
          divulgar o reproducir&nbsp;de&nbsp;cualquier forma&nbsp;y&nbsp;por
          cualquier medio los Contenidos&nbsp;y, en general,&nbsp;cualquier
          clase&nbsp;de&nbsp;material accesible a través del Sitio, salvo
          aquellos casos en que&nbsp;Bee&nbsp;haya autorizado expresamente su
          copia o reproducción.
        </p>

        <p>
          Por lo anterior, el Comercio deberá abstenerse en todo
          momento&nbsp;de&nbsp;(a) utilizar los Contenidos con fines contrarios
          a la ley, a la moral&nbsp;y&nbsp;a las buenas costumbres generalmente
          aceptadas o al orden público; (b) reproducir, copiar, distribuir,
          permitir el acceso del público a través&nbsp;de&nbsp;cualquier
          modalidad&nbsp;de&nbsp;comunicación pública, transformar o modificar
          los Contenidos, a menos que se cuente con la autorización del
          titular&nbsp;de&nbsp;los correspondientes derechos o ello resulte
          legalmente permitido; (c) suprimir, eludir o manipular cualquier
          clase&nbsp;de&nbsp;textos, leyendas o mensajes cuyo objeto sea la
          protección&nbsp;de&nbsp;los derechos&nbsp;de&nbsp;propiedad
          intelectual sobre los Contenidos, así como los dispositivos
          técnicos&nbsp;de&nbsp;protección que pudieren contener los Contenidos;
          (d) emplear los Contenidos&nbsp;y, en particular, la
          información&nbsp;de&nbsp;cualquier clase obtenida a través del Sitio
          para remitir publicidad, comunicaciones con fines&nbsp;de&nbsp;venta
          directa o con cualquier otra clase&nbsp;de&nbsp;finalidad comercial,
          mensajes no solicitados dirigidos a una
          pluralidad&nbsp;de&nbsp;personas con independencia&nbsp;de&nbsp;su
          finalidad, así como a abstenerse&nbsp;de&nbsp;comercializar los
          Contenidos.
        </p>

        <p>
          Se otorga autorización a los Comercios para desplegar en pantalla los
          Contenidos únicamente para uso personal no comercial, por lo que los
          Contenidos no deberán ser modificados&nbsp;de&nbsp;ninguna
          forma&nbsp;y&nbsp;conservarán todas las
          leyendas&nbsp;de&nbsp;derechos&nbsp;de&nbsp;autor&nbsp;y&nbsp;de&nbsp;otro
          tipo&nbsp;de&nbsp;propiedad contenidas en los mismos. Esta
          autorización podrá ser revocada por Bee en cualquier momento.
          Cualquier uso no autorizado&nbsp;de&nbsp;los Contenidos constituye una
          violación a estos&nbsp;Términos, a las
          leyes&nbsp;de&nbsp;derechos&nbsp;de&nbsp;autor&nbsp;y&nbsp;marcas
          comerciales, así como una violación a los tratados internacionales en
          materia&nbsp;de&nbsp;propiedad intelectual.
        </p>

        <p>
          <em>Licencias</em>
        </p>

        <p>
          Salvo lo expresamente señalado en la sección “Propiedad
          Intelectual”&nbsp;Bee&nbsp;no concede ninguna licencia o
          autorización&nbsp;de&nbsp;uso&nbsp;de&nbsp;ninguna clase sobre sus
          derechos&nbsp;de&nbsp;propiedad industrial e intelectual o sobre
          cualquier otra propiedad o derecho relacionado con el Sitio o los
          Contenidos.
        </p>

        <p>
          <em>Marcas</em>
        </p>

        <p>
          Las marcas, logos&nbsp;y&nbsp;lemas comerciales usados&nbsp;y/o
          desplegados en el Sitio son derechos de propiedad industrial
          registrados&nbsp;y&nbsp;no
          registrados&nbsp;de&nbsp;titularidad&nbsp;de, o autorizadas
          a,&nbsp;Bee&nbsp;(“Marcas”). Nada en este Sitio podrá ser interpretado
          como concesión&nbsp;de&nbsp;licencias o derechos&nbsp;de&nbsp;uso
          sobre cualesquiera Marcas desplegadas en el Sitio.
        </p>

        <p>
          <em>Violación&nbsp;de&nbsp;derechos&nbsp;de&nbsp;terceros</em>
        </p>

        <p>
          En el caso que cualquier Comercio o un tercero considere que
          cualquiera&nbsp;de&nbsp;los Contenidos ha sido introducido en el Sitio
          con violación&nbsp;de&nbsp;sus derechos&nbsp;de&nbsp;propiedad
          intelectual, dicho Comercio o tercero deberá enviar una notificación
          a&nbsp;Bee&nbsp;en la que se informe lo siguiente: (a) datos
          personales: nombre, dirección,
          número&nbsp;de&nbsp;teléfono&nbsp;y&nbsp;dirección&nbsp;de&nbsp;correo
          electrónico del reclamante; (b) indicación
          precisa&nbsp;y&nbsp;completa&nbsp;de&nbsp;los Contenidos supuestamente
          infringidos, así como&nbsp;de&nbsp;su localización en el Sitio; (c)
          declaración expresa&nbsp;y&nbsp;clara&nbsp;de&nbsp;que la
          utilización&nbsp;de&nbsp;los Contenidos indicados se ha realizado sin
          el consentimiento del titular&nbsp;de&nbsp;los
          derechos&nbsp;de&nbsp;propiedad intelectual supuestamente
          infringidos;&nbsp;y&nbsp;(d) declaración expresa,
          clara&nbsp;y&nbsp;bajo la responsabilidad del
          reclamante&nbsp;de&nbsp;que la información proporcionada en la
          notificación es exacta&nbsp;y&nbsp;de&nbsp;que la
          utilización&nbsp;de&nbsp;los Contenidos constituiría una
          violación&nbsp;de&nbsp;sus derechos&nbsp;de&nbsp;propiedad
          intelectual. Estas notificaciones deberán ser enviadas a
          info@puntosbee.com
        </p>

        <p>
          <em>Política&nbsp;de&nbsp;privacidad</em>
        </p>

        <p>
          Todo Comercio que pretenda acceder&nbsp;y/o utilizar los servicios
          ofrecidos por&nbsp;Bee en el Sitio, deberá cumplir en todo con
          estos&nbsp;Términos,
          las&nbsp;Condiciones&nbsp;de&nbsp;Reserva,&nbsp;y&nbsp;la Política de
          Privacidad y la Política de Protección de Datos Personales.
        </p>

        <p>
          <em>Responsabilidad del Comercio</em>
        </p>

        <p>
          El Comercio responderá por los daños&nbsp;y&nbsp;perjuicios
          que&nbsp;Bee&nbsp;pueda sufrir, directa o indirectamente, como
          consecuencia del
          incumplimiento&nbsp;de&nbsp;estos&nbsp;Términos&nbsp;o&nbsp;de&nbsp;la
          ley. El Comercio reconoce&nbsp;y&nbsp;acepta que el
          acceso&nbsp;y&nbsp;utilización del Sitio se realiza bajo su propia
          cuenta, riesgo&nbsp;y&nbsp;responsabilidad.
        </p>

        <p>
          <strong>II. Características del servicio</strong>
        </p>

        <p>
          <em>Programa puntos&nbsp;Bee</em>
        </p>

        <p>
          Puntos&nbsp;Bee (“Bee Puntos”)&nbsp;es una plataforma que centraliza
          programas de fidelización de clientes de&nbsp;diferentes
          establecimientos&nbsp;de&nbsp;comercio. Cada Comercio
          afiliado,&nbsp;de&nbsp;forma independiente, ofrece a sus Usuarios un
          programa&nbsp;de&nbsp;fidelización basado en puntos, los cuales pueden
          ser reclamados por premios en dicho Comercio (Bee Puntos son un
          servicio alojados en la Plataforma propiedad&nbsp;de&nbsp;Bee).
        </p>

        <p>
          <em>Registro en la Plataforma</em>
        </p>

        <p>
          La suscripción del Comercio está sujeta a los
          presentes&nbsp;Términos&nbsp;y&nbsp;Condiciones. Bee se reserva el
          derecho&nbsp;de&nbsp;afiliación,
          uso&nbsp;y&nbsp;exclusión&nbsp;de&nbsp;cualquier
          Comercio&nbsp;de&nbsp;su Plataforma.
        </p>

        <p>
          El registro en la Plataforma se realiza por medio del Sitio de la
          siguiente forma:
        </p>

        <p>
          <em>Paso 1: </em>El Comercio podrá crear una cuenta utilizando su
          correo electrónico&nbsp;y, en el proceso de registro, se recopilará la
          siguiente información del Comercio: Nombre, teléfono, correo
          electrónico y cualquier otra información de&nbsp;contacto. Bee se
          reserva el uso de esta información con fines comerciales.
        </p>

        <p>
          <em>Paso 2: </em>El Comercio deberá introducir su información de pagos
          por medio de nuestros de alguno de nuestros dos métodos de pago:
          Paypal o POS de BAC Credomatic. Bee no se hace responsable de
          cualquier situación relacionada al mal uso del método de pago, por lo
          cual si el Comercio tuviera algún inconveniente de cualquier tipo con
          el mismo deberá contactarse directamente con la empresa que
          proporcionó el método de pago. Además, el Comercio esta informado que
          se le solicitará al cliente que active la modalidad de pago mensual
          automático, en caso de que el cliente incumpla con su pago por alguna
          razón, se le cerrará la plataforma automáticamente y tendrá un plazo
          de 30 días para volver activarla, de lo contrario perderá toda la
          información que tenía en la misma. Lo único que quedará habilitado en
          el lector de Premios para no afectar a los usuarios que tienen puntos
          pendientes. Bee no se hace responsable de ningún tipo de daño que
          pueda tener el Comercio por el cierre de su plataforma por cualquier
          motivo.
        </p>

        <p>
          <em>Uso de la Plataforma</em>
        </p>

        <p>
          Una vez inscrito, el Comercio podrá utilizar las funcionalidades que
          la plataforma le brinda, estas están divididas en:
        </p>

        <ul>
          <li>
            <em>Creación de Premios: </em>El Comercio creará premios y
            promociones para que sus clientes puedan canjearlos una vez que
            acumulen los puntos necesarios. Se acumularán puntos por las compras
            que se realicen (Cada punto equivale a una divisa, Ejemplo: $1 = 1
            punto,&nbsp;de&nbsp;acuerdo con esta equivalencia, cada Comercio
            define la cantidad de puntos que equivalen cada premio, al igual que
            la vigencia o restricciones de estos. El Comercio se compromete a
            cumplir con la premio o promoción que publicó en la plataforma sin
            ninguna restricción o condición adicional a lo que mencionó en la
            descripción.
          </li>
        </ul>

        <ul>
          <li>
            <em>Visualización de Base de Datos &amp; Estadísticas: </em>Bee le
            ofrece al Comercio una plataforma de visualización de todos los
            clientes que han comprado en el comercio con la información de
            contacto de estos y otros datos relevantes como la frecuencia y el
            monto promedio de compra. Además, ofrece datos relevantes sobre la
            comunidad de clientes.
          </li>
        </ul>

        <ul>
          <li>
            <em>Plataforma de Notificaciones Push: </em>Esta plataforma enviará
            notificaciones a todos los clientes que hayan comprado en el
            Comercio, todo bajo las restricciones que Bee determine para evitar
            Spam para los clientes.
          </li>
        </ul>

        <ul>
          <li>
            <em>App Lector de Puntos para Tablet o Celular: </em>El Comercio
            deberá descargar el App: Lector Bee, por medio de este App, el
            Comercio podrá realizar dos funcionalidades:
            <ul>
              <li>Verificar los códigos QR de los premios de los clientes</li>
            </ul>
            <ul>
              <li>Dar puntos a los clientes cuando estos hacen su compra</li>
            </ul>
          </li>
        </ul>

        <ul>
          <li>
            <em>Instalación de Lector de Puntos: </em>En la esquina superior
            derecha de la plataforma se verá un código, este código es el cual
            se debe incluir en la Tablet o celular para activar el Lector.
          </li>
        </ul>

        <p>
          Los puntos acumulados en un Comercio se podrán utilizar en
          beneficios&nbsp;de&nbsp;ese comercio únicamente. Los puntos acumulados
          serán cargados a la cuenta del Cliente&nbsp;y&nbsp;son personales e
          intransferibles. El Cliente es el único autorizado a redimirlos por
          productos&nbsp;y/o servicios ofrecidos por El Comercio. El Comercio
          será el único responsable en verificar que la cantidad de puntos que
          se le entrega a cada cliente es la equivalente a la compra, al igual
          que verificar la identidad del cliente al momento de cambiar un
          premios. Bee no se hace responsable de cualquier situación relacionada
          al mal uso de estas funcionalidades actuales y funcionalidades futuras
          que se incorporarán a la Plataforma.
        </p>

        <p>
          <em>Retiro de Comercio</em>
        </p>

        <p>
          El Comercio podrá abandonar en cualquier momento el Programa Puntos
          Bee, o podrá eliminar su afiliación a La Plataforma. Para ello, el
          Miembro deberá enviar un correo electrónico a{" "}
          <a
            href="mailto:puntosbee@nw-ideas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            puntosbee@nw-ideas.com
          </a>
          &nbsp;con la solicitud&nbsp;de&nbsp;retirarse&nbsp;de&nbsp;ese
          comercio y estar al día con los pagos. La solicitud será procesada
          dentro&nbsp;de&nbsp;los 15 días hábiles siguientes a la recepción del
          correo electrónico.
        </p>

        <p>
          Asimismo, Bee se reserva el
          derecho&nbsp;de&nbsp;retirar&nbsp;de&nbsp;la Plataforma a los
          Comercios que realicen un uso indebido&nbsp;de&nbsp;la cuenta&nbsp;y/o
          por incumplimiento&nbsp;de&nbsp;lo establecido en el presente
          reglamento. Se considera un uso indebido&nbsp;de&nbsp;la cuenta,
          aquellos eventos en los cuales los puntos acumulados son
          conseguidos&nbsp;de&nbsp;manera ilegal o fraudulenta. Bee no se
          responsabiliza por el uso indebido o fraude que este hecho ocasione.
          No se permite la comercialización&nbsp;de&nbsp;puntos&nbsp;y/o
          beneficios&nbsp;de&nbsp;la Plataforma por parte&nbsp;de&nbsp;los
          Comercios o Usuarios, en caso&nbsp;de&nbsp;ser comprobada se procederá
          a la exclusión inmediata de&nbsp;la Plataforma. Se aclara que los
          puntos no son canjeables por dinero en efectivo.
        </p>

        <p>
          <em>En cuanto a los periodos gratuitos de prueba de un mes:</em>
        </p>

        <p>
          Bee ofrece un periodo de prueba gratis de un mes, en el cual los
          Comercios pueden probar la plataforma completa.
        </p>

        <p>
          Si el Comercio se encuentra dentro del periodo gratuito de prueba de
          un mes y desea retirarse de la plataforma, debe hacerlo dentro de este
          mes de prueba, de lo contrario se le cobrará la mensualidad de $29 en
          los primeros días del mes siguiente y no podrá hacer reclamos. El
          Comercio comprende y renuncia a hacer reclamos o contra-cargos si no
          cumplió con dicha condición.
        </p>

        <p>
          <em>Responsabilidad del Comercio</em>
        </p>

        <p>
          Al ingresar a La Plataforma, el Comercio adquiere el
          compromiso&nbsp;de&nbsp;suministrar información
          correcta&nbsp;y&nbsp;verdadera, así como el
          compromiso&nbsp;de&nbsp;proceder a la actualización&nbsp;de&nbsp;sus
          datos cada vez que se requiera. El Comercio se obliga a notificar
          oportunamente a Bee al correo{" "}
          <a
            href="mailto:puntosbee@nw-ideas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            puntosbee@nw-ideas.com
          </a>
          &nbsp;la pérdida o hurto&nbsp;de&nbsp;la clave personal del Programa,
          con el fin&nbsp;de&nbsp;bloquear la cuenta.
        </p>

        <p>
          El Comercio acepta no utilizar ningún dispositivo, software, rutina ni
          datos para obstruir o intentar obstruir el funcionamiento
          correcto&nbsp;de&nbsp;La Plataforma&nbsp;y&nbsp;de&nbsp;toda actividad
          realizada en el mismo y&nbsp;estarán sujetos a las acciones
          penales&nbsp;y&nbsp;legales que se desprendan&nbsp;de&nbsp;dichas
          actuaciones.
        </p>

        <p>
          <strong>III.&nbsp;Condiciones&nbsp;generales</strong>
        </p>

        <p>
          <em>Ley aplicable&nbsp;y&nbsp;jurisdicción</em>
        </p>

        <p>
          Estas&nbsp;Condiciones&nbsp;Generales se rigen por las leyes&nbsp;del
          país donde el comercio esta inscrito. Cualquier controversia que
          derive&nbsp;de&nbsp;este documento se someterá a los jueces
          competentes de&nbsp;acuerdo con la legislación del país.
        </p>

        <p>
          <em>Sobre Pagos</em>
        </p>

        <p>
          El Comercio acepta expresamente que esta consiente que los cobros de
          Bee son automáticos a la tarjeta de crédito o débito que ingreso en la
          plataforma al momento de registrarse. Los cobros se realizan por mes
          adelantado por el monto mensual de $29.
        </p>

        <p>
          Bee no hará ningún reembolso de la mensualidad en caso de que el
          cliente no quisiera seguir con los servicios cuando ya se le facturó
          el mes en el que se encuentra.
        </p>

        <p>
          <em>Indemnidad</em>
        </p>

        <p>
          El Comercio acepta expresamente que mantendrá indemne a Bee, sus
          sociedades subsidiarias, afiliadas&nbsp;y&nbsp;empleados, por los
          daños&nbsp;y&nbsp;perjuicios que pudieren sufrir con
          ocasión&nbsp;de&nbsp;una utilización inapropiada de la Plataforma. Por
          utilización inapropiada se entenderá toda utilización de la Plataforma
          que se realice sin cumplir lo dispuesto en la ley&nbsp;y&nbsp;en
          estos&nbsp;Términos.
        </p>

        <p>
          <em>Responsabilidad civil extracontractual</em>
        </p>

        <p>
          El Comercio reconoce&nbsp;y&nbsp;acepta expresamente que Bee no es
          responsable por las conductas difamatorias, ofensivas,
          ilegales&nbsp;y/o delictivas&nbsp;de&nbsp;sus Comercios y Usuarios.
        </p>

        <p>
          <em>Seguridad informática – cookies</em>
        </p>

        <p>
          Las cookies son pequeños archivos&nbsp;de&nbsp;datos que los sitios
          web como o los correos electrónicos guardan en su
          buscador&nbsp;y&nbsp;opcionalmente en el disco duro. Las cookies
          permiten “recordar” la información sobre las
          preferencias&nbsp;de&nbsp;los
          Comercios&nbsp;y&nbsp;sesión,&nbsp;y&nbsp;le permite desplazarse
          dentro&nbsp;de&nbsp;las áreas&nbsp;de&nbsp;nuestros sitios web sin
          volver a introducir sus datos. Esto hace posible crear una
          experiencia&nbsp;de&nbsp;compras más
          personalizada&nbsp;y&nbsp;cómoda.&nbsp;www.puntosbee.com&nbsp;usa un
          tercero para la implementación&nbsp;de&nbsp;cookies en su computador,
          para recopilar información que no sea personal e identificable. A
          pesar&nbsp;de&nbsp;que las cookies contienen un número
          único&nbsp;de&nbsp;Comercio o Usuario, no recopilan ni guardan ninguna
          información personal identificable.
        </p>

        <p>
          Aunque el Comercio puede configurar su navegador&nbsp;de&nbsp;Internet
          para que no acepte cookies, estas son necesarias para poder ver, crear
          una cuenta, o hacer compras por medio de la Plataforma. Si el Comercio
          no tiene las cookies activadas, no podrá utilizar la Plataforma. El
          buscador&nbsp;de&nbsp;Internet recoge automáticamente
          información&nbsp;de&nbsp;la página web que el Comercio consultó
          antes&nbsp;de&nbsp;visitar www.puntosbee.com, el navegador que
          utilizó&nbsp;y&nbsp;cualquier término&nbsp;de&nbsp;búsqueda que haya
          ingresado en nuestro sitio, entre otras cosas. La Plataforma también
          podrá usar otras tecnologías para rastrear las páginas que visitan los
          Comercios para garantizarles una mejor&nbsp;y&nbsp;más segura
          experiencia&nbsp;de&nbsp;compra&nbsp;y&nbsp;para ayudarnos a entender
          cómo los visitantes usan nuestro sitio web.
        </p>

        <p>
          <em>Derechos&nbsp;de&nbsp;Propiedad Intelectual</em>
        </p>

        <p>
          Todo el material informático, gráfico, publicitario,
          fotográfico,&nbsp;de&nbsp;multimedia,
          audiovisual&nbsp;y/o&nbsp;de&nbsp;diseño, así como todos los
          contenidos, textos&nbsp;y&nbsp;bases&nbsp;de&nbsp;datos (en adelante
          “los Contenidos”), puestos a su disposición en esta Plataforma
          son&nbsp;de&nbsp;propiedad exclusiva de Bee, o en algunos
          casos,&nbsp;de&nbsp;terceros que han autorizado su uso&nbsp;y/o
          explotación.
        </p>

        <p>
          Igualmente, el uso&nbsp;de&nbsp;algunos
          contenidos&nbsp;de&nbsp;propiedad&nbsp;de&nbsp;terceros se encuentra
          expresamente autorizado por la Ley&nbsp;y&nbsp;están protegidos por
          las normas sobre derechos&nbsp;de&nbsp;autor, marcas&nbsp;y&nbsp;por
          todas las normas nacionales e internacionales que le sean aplicables.
        </p>

        <p>
          Queda prohibido todo acto&nbsp;de&nbsp;copia, reproducción,
          modificación, creación&nbsp;de&nbsp;trabajos derivados, venta o
          distribución, exhibición&nbsp;de&nbsp;los Contenidos, entre
          otros,&nbsp;de&nbsp;cualquier manera, o por cualquier medio,
          incluyendo, mas no limitado a, medios electrónicos, mecánicos, de
          fotocopiado, de grabación o&nbsp;de&nbsp;cualquier otra índole, sin el
          permiso previo por escrito&nbsp;de&nbsp;Bee o del respectivo
          titular&nbsp;de&nbsp;los derechos&nbsp;de&nbsp;autor.
        </p>

        <p>
          En ningún caso los
          presentes&nbsp;Términos&nbsp;y&nbsp;Condiciones&nbsp;confieren
          derechos, licencias&nbsp;y/o autorizaciones para realizar los actos
          anteriormente descritos. Cualquier uso no autorizado&nbsp;de&nbsp;los
          Contenidos, constituirá una violación a los
          presentes&nbsp;Términos&nbsp;y&nbsp;Condiciones&nbsp;y&nbsp;a las
          normas vigentes sobre marcas, derechos&nbsp;de&nbsp;autor&nbsp;y/u
          otras normas&nbsp;de&nbsp;propiedad intelectual tanto nacionales e
          internacionales aplicables.
        </p>

        <p>
          <em>Exoneración&nbsp;y&nbsp;garantías</em>
        </p>

        <p>
          En ningún caso los
          presentes&nbsp;Términos&nbsp;y&nbsp;Condiciones&nbsp;confieren
          derechos, licencias&nbsp;y/o autorizaciones para realizar los actos
          anteriormente descritos. Cualquier uso no autorizado&nbsp;de&nbsp;los
          Contenidos, constituirá una violación a los
          presentes&nbsp;Términos&nbsp;y&nbsp;Condiciones&nbsp;y&nbsp;a las
          normas vigentes sobre marcas, derechos&nbsp;de&nbsp;autor&nbsp;y/u
          otras normas&nbsp;de&nbsp;propiedad intelectual tanto nacionales e
          internacionales aplicables.
        </p>

        <p>
          Bee advierte a los Comercios que la información&nbsp;de&nbsp;este
          Sitio puede contener errores o inexactitudes, no estar completa o
          actualizada. Por ello, El Sitio se reserva el
          derecho&nbsp;de&nbsp;corregir cualquier error, omisión o inexactitud,
          cambiar o actualizar la misma en cualquier momento&nbsp;y&nbsp;sin
          previo aviso. Además, Bee no garantiza que la plataforma este en línea
          un 100% del tiempo, es por esta razón que el Comercio rechaza
          cualquier tipo de represalia contra Bee en caso de que la Plataforma
          falle.
        </p>

        <ol type="I">
          <li>
            Publicidad&nbsp;y&nbsp;vínculos Con respecto a las empresas,
            productos&nbsp;y/o servicios a los cuales se les haga publicidad en
            el Sitio, así como los enlaces (links), Bee no cumple ninguna
            función en la producción&nbsp;de&nbsp;esos productos&nbsp;y/o la
            prestación&nbsp;de&nbsp;servicios&nbsp;y&nbsp;no constituye ninguna
            garantía, expresa o implícita&nbsp;de&nbsp;su contenido
            o&nbsp;de&nbsp;su adecuación para un propósito particular. En este
            orden&nbsp;de&nbsp;ideas, cuando se sugiera la
            utilización&nbsp;de&nbsp;algún producto&nbsp;y/o servicio,
            es&nbsp;de&nbsp;exclusiva autonomía del Usuario (Cliente del
            Comercio) y el Comercio decidir su utilización,&nbsp;y&nbsp;por lo
            tanto&nbsp;Bee&nbsp;no asume ninguna responsabilidad por los
            perjuicios directos o indirectos, ciertos o eventuales, pasados,
            presentes o futuros, que se llegaren a derivar&nbsp;de&nbsp;dicha
            utilización.
          </li>
        </ol>

        <ol type="I">
          <li>
            Publicidad&nbsp;y&nbsp;vínculos Con respecto a las empresas,
            productos&nbsp;y/o servicios a los cuales se les haga publicidad en
            la Plataforma, así como los enlaces (links),&nbsp;Bee&nbsp;no cumple
            ninguna función en la producción&nbsp;de&nbsp;esos
            productos&nbsp;y/o la
            prestación&nbsp;de&nbsp;servicios&nbsp;y&nbsp;no constituye ninguna
            garantía, expresa o implícita&nbsp;de&nbsp;su contenido
            o&nbsp;de&nbsp;su adecuación para un propósito particular. En este
            orden&nbsp;de&nbsp;ideas, cuando se sugiera la
            utilización&nbsp;de&nbsp;algún producto&nbsp;y/o servicio, es de
            exclusiva autonomía del Usuario (Cliente del
            Comercio)&nbsp;y&nbsp;del Comercio decidir su
            utilización,&nbsp;y&nbsp;por lo tanto&nbsp;Bee&nbsp;no asume ninguna
            responsabilidad por los perjuicios directos o indirectos, ciertos o
            eventuales, pasados, presentes o futuros, que se llegaren a
            derivar&nbsp;de&nbsp;dicha utilización.
          </li>
        </ol>

        <p>
          <em>Protección&nbsp;de&nbsp;datos personales</em>
        </p>

        <p>
          Al aceptar
          los&nbsp;términos&nbsp;y&nbsp;condiciones&nbsp;legales&nbsp;y&nbsp;la
          política&nbsp;de&nbsp;tratamiento&nbsp;de&nbsp;datos, el Comercio
          indica que conoce&nbsp;y&nbsp;autoriza&nbsp;de&nbsp;manera previa,
          expresa e informada a&nbsp;Bee, sus filiales y vinculadas, para que
          sus datos personales puedan ser almacenados&nbsp;y&nbsp;usados con el
          fin&nbsp;de&nbsp;lograr una eficiente comunicación durante el presente
          trámite o actividad&nbsp;y&nbsp;autoriza en los mismos Términos, que
          dicha información pueda ser tratada conforme a lo dispuesto en la
          Ley&nbsp;y&nbsp;sus Decretos Reglamentarios. Así mismo, el Comercio ha
          sido informado acerca&nbsp;de&nbsp;la política para la
          protección&nbsp;de&nbsp;datos personales disponible en La Plataforma,
          en la cual se incluyen los
          procedimientos&nbsp;de&nbsp;consulta&nbsp;y&nbsp;reclamación que
          permiten hacer efectivos los derechos&nbsp;de&nbsp;los Comercios al
          acceso, conocimiento, consulta, rectificación,
          actualización,&nbsp;y&nbsp;supresión&nbsp;de&nbsp;los datos, e
          igualmente el Cliente podrá presentar cualquier solicitud referida a
          los datos personales a través&nbsp;de la página web{" "}
          <a href="http://www.puntosbee.com">www.puntosbee.com</a> y&nbsp;en el
          correo electrónico para Comercios{" "}
          <a
            href="mailto:puntosbee@nw-ideas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            puntosbee@nw-ideas.com.
          </a>
        </p>

        <p>
          <strong>Documento completo</strong>
        </p>

        <p>
          Estos Términos y la Política de Privacidad, forman un documento único
          que debe interpretarse y cumplirse en su conjunto.
        </p>

        <p>
          <strong>Consultas</strong>
        </p>

        <p>
          Cualquier inquietud relacionada con el uso de La Aplicación podrá
          hacerse a través de{" "}
          <a href="mailto:info@nw-ideas.com">info@nw-ideas.com</a>.
        </p>

        <p>
          <em>Documento completo</em>
        </p>

        <p>
          Estos&nbsp;Términos,
          las&nbsp;Condiciones&nbsp;de&nbsp;Reserva&nbsp;y&nbsp;la
          Política&nbsp;de&nbsp;Privacidad, forman un documento único que debe
          interpretarse&nbsp;y&nbsp;cumplirse en su conjunto.
        </p>

        <p>
          <em>Consultas</em>
        </p>

        <p>
          Cualquier inquietud relacionada con el uso del Sitio podrá hacerse a
          través&nbsp;de{" "}
          <a
            href="mailto:puntosbee@nw-ideas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            puntosbee@nw-ideas.com
          </a>
          .
        </p>
        <div className="post-tags"></div>
      </div>
    </div>
  );
};
