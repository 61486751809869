import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  pageSize: 10,
  page: 1,
  since: undefined,
  until: undefined,
  count: 0,
  data: [],
};

export const prizeRedeemedSlice = createSlice({
  name: "store/PrizeRedeemed",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    loaded: (state, action) => {
      const { result, total_pages } = action.payload;
      state.isLoading = false;
      state.error = null;
      state.data = result;
      state.count = total_pages;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = null;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setDateRange: (state, action) => {
      state.page = 1;
      state.since = action.payload.since || undefined;
      state.until = action.payload.until || undefined;
    },
  },
});
export const prizeRedeemedActions = prizeRedeemedSlice.actions;
export default prizeRedeemedSlice.reducer;
