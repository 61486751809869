import { createSlice } from "@reduxjs/toolkit";
import { info } from "toastr";

const initialState = {
  isLoading: false,
  isOpen: false,
  saving: false,
  error: null,
  items: [],
  prize: null,
  count: 0,
  page: 1,
  selectedForEdit: null,
};

export const prizesSlice = createSlice({
  name: "prize/prizes",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
      //state.items = [];
    },
    loaded: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.items = action.payload.result;
      state.count = action.payload.total_pages;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.saving = false;
      state.error = action.payload;
      state.items = [];
    },
    setPrize: (state, action) => {
      state.prize = action.payload;
      state.isLoading = false;
    },
    toggleItem: (state, action) => {
      const checked = state.items.filter((item) => item.checked).length;
      if (checked < 3 || action.payload.checked) {
        const newItems = state.items.map((item) => {
          if (item.id !== action.payload.id) {
            return item;
          }
          item.checked = !item.checked;
          return item;
        });
        state.items = [...newItems];
      }
    },
    creating: (state, action) => {
      state.saving = true;
      state.error = null;
    },
    created: (state, action) => {
      state.items = [...state.items, { ...action.payload, checked: false }];
      state.saving = false;
      state.isOpen = false;
    },
    setOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    deleted: (state, { payload }) => {
      state.items = state.items.filter((item) => item.id !== payload);
    },
    setForEdit: (state, { payload }) => {
      state.selectedForEdit = payload;
      state.isOpen = !!payload;
    },
    update: (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }

        return item;
      });
      state.isOpen = false;
      state.selectedForEdit = null;
    },
  },
});
export const prizesSliceActions = prizesSlice.actions;
export default prizesSlice.reducer;
