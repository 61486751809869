import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  saving: false,
  users: [],
  page: 1,
  count: 0,
  isOpen: false,
  searchWord: null,
  dates: {},
  memberInfo: null,
};

export const storeMemberSlice = createSlice({
  name: "store/members",
  initialState,
  reducers: {
    load: (state, _) => {
      state.isLoading = true;
    },
    loaded: (state, action) => {
      state.isLoading = false;
      state.users = action.payload.result.map((user) => {
        if (!user.url_user_image) {
          user.url_user_image = "/assets/default_member.png";
        }
        return user;
      });
      state.count = action.payload.total_pages;
    },
    error: (state, _) => {
      state.isLoading = false;
      state.saving = false;
    },
    save: (state, _) => {
      state.saving = true;
    },
    create: (state, action) => {
      state.saving = false;
      state.isOpen = false;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    search: (state, { payload }) => {
      state.isLoading = true;
      state.page = 1;
      state.searchWord = payload;
    },
    setDateRange: (state, action) => {
      state.dates = {
        start_date: action.payload.since,
        end_date: action.payload.until,
      };
    },
    setMember: (state, action) => {
      const info = action.payload;
      if (!info?.user_info?.url_user_image) {
        info.user_info.url_user_image = `${process.env.PUBLIC_URL}/assets/default_member.png`;
      }
      state.memberInfo = info;
      state.isLoading = false;
    },
    setOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});
export const storeMemberActions = storeMemberSlice.actions;
export default storeMemberSlice.reducer;
