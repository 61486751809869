import { configureStore } from "@reduxjs/toolkit";
import storeReducer from "./slices/storeSlice";
import prizeReducer from "./slices/prizes";
import storeSubscriptionReducer from "./slices/store";
import uiReducer from "./slices/ui/slice";
import countriesReducer from "./slices/countries/slice";
import authReducer from "./slices/auth";
import notifications from "./slices/notifications/slice"

export const store = configureStore({
  reducer: {
    store: storeReducer,
    prize: prizeReducer,
    storeSubscription: storeSubscriptionReducer,
    ui: uiReducer,
    countries: countriesReducer,
    auth: authReducer,
    notifications
  },
});
